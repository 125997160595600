import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-icon',
  styleUrl: 'x-icon.sass',
  assetsDirs: ['assets'],
})
export class XIcon {
  @Prop() glyph: string;
  @Prop({ reflect: true }) size: string;
  @Prop({ reflect: true }) color: 'primary' | 'secondary' | 'error';

  render() {
    return (
      <Host>
        <svg>
          <use xlinkHref={`#icon_${this.glyph}`}></use>
        </svg>
      </Host>
    );
  }
}
