x-icon
  display: inline-flex
  width: 100%
  height: 100%
  font-size: 0
  line-height: 0
  flex: 0 0 auto
  svg
    width: 100%
    height: 100%
    fill: currentColor
    &:not(:root)
      overflow: hidden

  @each $size in (8, 12, 16, 20, 24, 30, 32)
    &[size="#{$size}"]
      width: #{$size}px
      height: #{$size}px
  &[color="primary"]
    color: var(--icon-primary)
  &[color="secondary"]
    color: var(--icon-dark-grey)
  &[color="dark-50"]
    color: var(--icon-dark-50)
